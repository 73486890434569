import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Footer from './components/Footer';
import HomeMainPage from './components/HomeMainPage';
import Navbar from './components/Navbar';
import DigitalMarketing from './components/DigitalMarketing';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<HomeMainPage />} />
          <Route path="/digital" element={<DigitalMarketing />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
