import React from 'react'
import LandingPage from './LandingPage'
import AboutDigital from './AboutDigital'
import DigitalBrand from './DigitalBrand'
import DigitalService from './DigitalService'

export default function DigitalMarketing() {
  return (
    <>
    <LandingPage/>
    <AboutDigital/>
    <DigitalBrand/>
    <DigitalService/>
    </>
  )
}
