import React, { useState, useEffect } from 'react';

const AboutDigital = () => {
    // Countdown logic
    const calculateTimeLeft = () => {
        const endDate = new Date('2024-06-14');
        const now = new Date();
        const difference = endDate - now;

        let timeLeft = {};
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }
        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearTimeout(timer);
    }, [timeLeft]);

    return (
        <div className="min-h-full bg-gray-50 py-20 px-4 sm:px-6 lg:px-20">
            <div className="container mx-auto flex flex-col lg:flex-row justify-center items-center">
                {/* Left Section */}
                <div className="lg:w-1/2 lg:pr-12 mb-10 lg:mb-0">
                    <div className=" px-4 py-8 rounded-lg transition-transform duration-300 hover:scale-105">
                        <h1 className="text-2xl sm:text-3xl lg:text-4xl font-extrabold text-gray-900 mb-6 lg:mb-8 leading-tight sm:leading-snug">
                            DIGITAL MARKETING AGENCY
                        </h1>
                        <p className="mt-4 text-base sm:text-lg text-gray-600 leading-relaxed">
                            Partner with the GCC region's top digital agency for luxury, retail, and public sectors and experience the full power of ASTUDIO 360-degree digital marketing services. We'll build and manage your online brand presence, captivate your audience, and help you reach new markets.
                            <br />
                            <br />
                            <span className="text-indigo-600 font-semibold">Get in touch</span> for bespoke solutions and team up with the industry leaders.
                        </p>
                    </div>

                    <div className="mt-8 bg-white  mx-4 p-4 sm:py-8  shadow-2xl rounded-lg grid grid-cols-3 sm:grid-cols-3 gap-4 text-center">
                        <div className="transition-transform duration-300 hover:scale-105">
                            <h2 className="text-2xl sm:text-3xl font-bold text-gray-900">28,000</h2>
                            <p className="text-gray-600 text-sm sm:text-base">Competitions</p>
                        </div>
                        <div className="transition-transform duration-300 hover:scale-105">
                            <h2 className="text-2xl sm:text-3xl font-bold text-gray-900">5,000</h2>
                            <p className="text-gray-600 text-sm sm:text-base">Write-ups</p>
                        </div>
                        <div className="transition-transform duration-300 hover:scale-105">
                            <h2 className="text-2xl sm:text-3xl font-bold text-gray-900">70+ hours</h2>
                            <p className="text-gray-600 text-sm sm:text-base">Courses</p>
                        </div>
                    </div>
                </div>

                {/* Right Section with Carousel */}
                <div className="relative lg:w-1/2 flex justify-center items-center mt-12 lg:mt-0">
                    <div className="bg-white shadow-xl rounded-xl overflow-hidden w-full max-w-lg lg:max-w-2xl relative z-10">
                        {/* Mockup Header with Circles */}
                        <div className="p-4 bg-gray-50 border-b border-gray-200 flex items-center space-x-2">
                            <div className="w-3 h-3 bg-red-400 rounded-full"></div>
                            <div className="w-3 h-3 bg-yellow-400 rounded-full"></div>
                            <div className="w-3 h-3 bg-green-400 rounded-full"></div>
                        </div>

                        {/* Mockup Content with Vertical Scrolling Carousel */}
                        <div className="p-6">
                            <div className="h-72 sm:h-80 lg:h-96 overflow-y-scroll">
                                <div className="carousel-item w-full mb-4">
                                    <img src="https://www.kaggle.com/static/images/home/logged-out/datasets-landing@1.png" alt="Datasets" className="w-full object-contain" />
                                </div>
                                <div className="carousel-item w-full mb-4">
                                    <img src="https://www.kaggle.com/static/images/home/logged-out/notebooks-landing@1.png" alt="Notebooks" className="w-full object-contain" />
                                </div>
                                <div className="carousel-item w-full mb-4">
                                    <img src="https://www.kaggle.com/static/images/home/logged-out/models-landing@1.png" alt="Models" className="w-full object-contain" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutDigital;
