import React from 'react';
import branding from '../images/branding.jpg';
import website from '../images/website.jpg'
import seo from '../images/SEO.jpg'
import social from '../images/social.jpg'
import Influencer from '../images/influ.jpg'
import socail2 from '../images/socail2.jpg'


const DigitalService = () => {
  const competitions = [
    {
      title: 'Digital Brand Strategy',
      image: branding,
    },
    {
      title: 'High-End Website Design and Development',
      image: website,
    },
    {
      title: 'Search Engine Optimization (SEO)',
      image: seo,
    }, 
    {
      title: 'Social Media Marketing (SMM)',
      image: social,
    }, 
    {
      title: 'Influencer Marketing',
      image: Influencer,
    },
    {
      title: 'Search Engine Marketing – Pay Per Click (PPC)',
      image: socail2,
    },
  ];

  return (
    <div className="px-8 py-12 bg-gray-100 min-h-screen flex flex-col items-center">
      <h3 className="text-2xl lg:text-3xl font-bold text-gray-800 mb-8">DIGITAL MARKETING SERVICES</h3>

      {/* Cards section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 w-full max-w-7xl">
        {competitions.map((comp, index) => (
          <div
            key={index}
            className="relative group bg-white border border-gray-200 rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow transform hover:-translate-y-2 hover:scale-105 duration-300"
          >
            {/* Image with animated zoom effect */}
            <div className="overflow-hidden rounded-t-lg">
              <img
                src={comp.image}
                alt={comp.title}
                className="w-full h-48 object-cover transition-transform duration-500 group-hover:scale-110"
              />
            </div>

            {/* Title below the image */}
            <div className="p-4 text-center">
              <h4 className="text-lg font-semibold text-gray-900 transition-all duration-300 group-hover:text-orange-500">
                {comp.title}
              </h4>
            </div>

            {/* Animated glow effect */}
            <div className="absolute inset-0 opacity-0 bg-gradient-to-r from-orange-400 via-pink-500 to-purple-500 group-hover:opacity-50 transition duration-500"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DigitalService;
