


import React from 'react';
import digital1 from '../images/digital1.jpg';

const DigitalBrand = () => {
  return (
    <div className="relative bg-gray-900 w-full min-h-screen flex justify-center items-center">
      {/* Main Container */}
      <div className="relative w-full max-w-7xl flex flex-col lg:flex-row justify-between items-stretch bg-white bg-opacity-90 shadow-xl rounded-lg overflow-hidden transition duration-700 ease-in-out opacity-0 transform translate-y-4 animate-fadeIn">

        {/* Left Image Section with Gradient Overlay */}
        <div className="w-full lg:w-1/2 relative h-64 lg:h-auto">
          <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-50 z-10"></div>
          <div
            className="w-full h-full bg-cover bg-center"
            style={{ backgroundImage: `url(${digital1})` }}
          ></div>
        </div>

        {/* Right Text Section */}
        <div className="w-full lg:w-1/2 flex justify-center items-center p-8 lg:p-12 bg-gray-50">
          <div className="max-w-md">
            <h2 className="text-2xl sm:text-3xl lg:text-3xl font-bold text-gray-900 leading-tight">
              Are you ready to take your <span className="text-orange-500">luxury brand</span> or <span className="text-orange-500">public sector organization</span> to new heights?
            </h2>
            <p className="mt-4 text-sm sm:text-base lg:text-lg text-gray-700">
              At <span className="font-semibold">ASTUDIO</span>, we specialize in crafting bespoke digital marketing strategies that deliver exceptional results. Our unique blend of creativity, technical expertise, and industry knowledge has earned us the trust of some of the world’s most prestigious brands and government organizations.
            </p>
            <div className="mt-8 flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-4">
              <button className="px-6 py-3 bg-orange-500 hover:bg-orange-600 transition-all text-white font-bold rounded-lg flex items-center justify-center shadow-md transform hover:scale-105">
                GET IN TOUCH
              </button>
              <button className="px-6 py-3 bg-gray-800 hover:bg-gray-900 transition-all text-white font-bold rounded-lg flex items-center justify-center shadow-md transform hover:scale-105">
                EXPLORE MORE
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Floating Action Button with Pulse Effect */}
      <div className="fixed bottom-10 right-10">
        <button className="p-4 bg-orange-500 hover:bg-orange-600 transition-all text-white rounded-full shadow-lg text-2xl transform hover:scale-110 animate-pulse">
          ⚡
        </button>
      </div>

      {/* Inline Animations */}
      <style>{`
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(20px); }
          to { opacity: 1; transform: translateY(0); }
        }
        .animate-fadeIn {
          animation: fadeIn 1s ease-in-out forwards;
        }
      `}</style>
    </div>
  );
};

export default DigitalBrand;
