import React from 'react'
import LandingPage from './LandingPage'
import OverviewSection from './OverviewSection'
import Offerings from './Offerings'
import TextImageSection from './TextImageSection'
import SolutionsSection from './SolutionsSection'
import TechnologiesSection from './TechnologiesSection'
import IndustryExpertise from './IndustryExpertise'
import ContactUsSection from './ContactUsSection'
import Footer from './Footer'

export default function HomeMainPage() {
  return (
    <>
      <LandingPage/>   
        <OverviewSection/>
    <Offerings/>
     <TextImageSection/>
     <SolutionsSection/>
     <TechnologiesSection/>
    <IndustryExpertise/>
     <ContactUsSection/>
    
    </>
    
  )
}
